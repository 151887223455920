import AuthConfig from './authConfig.js';

class AuthConfigHandler {
  constructor(config) {
    this.config = new AuthConfig();
    this.config.define('authDomain');
    this.config.define(
      'displayMode',
      AuthConfigHandler.DisplayMode.IDENTIFIER_FIRST,
    );
    this.config.define('tenants');
    this.setConfig(config);
  }

  setConfig(config) {
    for (let name in config) {
      // eslint-disable-next-line no-prototype-builtins
      if (config.hasOwnProperty(name)) {
        try {
          this.config.update(name, config[name]);
        } catch (e) {
          console.error(`Invalid config: "${name}"`);
        }
      }
    }
  }

  getAuthDomain() {
    const authDomain = this.config.get('authDomain');
    if (!authDomain) {
      throw new Error('Invalid project configuration: authDomain is required!');
    }
    return authDomain;
  }

  getDisplayMode() {
    const displayMode = this.config.get('displayMode');
    for (let key in AuthConfigHandler.DisplayMode) {
      if (AuthConfigHandler.DisplayMode[key] === displayMode) {
        return AuthConfigHandler.DisplayMode[key];
      }
    }
    return AuthConfigHandler.DisplayMode.IDENTIFIER_FIRST;
  }

  validateTenantId(tenantId) {
    const configs = this.config.get('tenants');
    if (
      !configs ||
      // eslint-disable-next-line no-prototype-builtins
      (!configs.hasOwnProperty(tenantId) &&
        // eslint-disable-next-line no-prototype-builtins
        !configs.hasOwnProperty(
          AuthConfigHandler.ConfigKeys.DEFAULT_CONFIG_KEY,
        ))
    ) {
      throw new Error('Invalid tenant configuration!');
    }
  }

  getTenantConfig_(tenantId) {
    this.validateTenantId(tenantId);
    const configs = this.config.get('tenants');
    return (
      configs[tenantId] ||
      configs[AuthConfigHandler.ConfigKeys.DEFAULT_CONFIG_KEY]
    );
  }

  getProvidersForTenant(tenantId, email = undefined) {
    const configs = this.config.get('tenants');
    if (!configs) {
      throw new Error('Invalid tenant configuration!');
    }
    const providers = [];
    const tenantConfig =
      configs[tenantId] ||
      configs[AuthConfigHandler.ConfigKeys.DEFAULT_CONFIG_KEY];
    if (!tenantConfig) {
      console.error(
        `Invalid tenant configuration: ` + `${tenantId} is not configured!`,
      );
      return providers;
    }
    const signInOptions = tenantConfig['signInOptions'];
    if (!signInOptions) {
      throw new Error(
        'Invalid tenant configuration: signInOptions are invalid!',
      );
    }
    signInOptions.forEach((option) => {
      if (typeof option === 'string') {
        // No hd configured, treat like a match for any email.
        providers.push(option);
      } else if (typeof option['provider'] === 'string') {
        const hd = option['hd'];
        // If hd is configured, match the email with the hd.
        if (hd && email) {
          const regex =
            hd instanceof RegExp
              ? hd
              : new RegExp('@' + hd.replace('.', '\\.') + '$');
          if (regex.test(email)) {
            providers.push(option['provider']);
          }
        } else {
          // No hd configured, treat like a match for any email.
          providers.push(option['provider']);
        }
      } else {
        console.error(
          `Invalid tenant configuration: signInOption ` +
            `${JSON.stringify(option)} is invalid!`,
        );
      }
    });
    return providers;
  }

  getSignInConfigForTenant(tenantId, providerIds = undefined) {
    const signInConfig = this.filterTenantConfig_(
      tenantId,
      AuthConfigHandler.SUPPORTED_SIGN_IN_CONFIG_KEYS,
    );
    const signInOptions = signInConfig['signInOptions'];
    if (signInOptions && providerIds) {
      const eligibleOptions = signInOptions.filter((option) => {
        if (typeof option === 'string') {
          return providerIds.includes(option);
        } else {
          return providerIds.includes(option['provider']);
        }
      });
      signInConfig['signInOptions'] = eligibleOptions;
    }
    return signInConfig;
  }

  filterProperties(source, keys, initialValue = {}) {
    return Object.keys(source)
      .filter((key) => keys.includes(key))
      .reduce((obj, key) => {
        obj[key] = source[key];
        return obj;
      }, initialValue);
  }

  filterTenantConfig_(tenantId, keys, baseConfig = {}) {
    const uiConfig = this.getTenantConfig_(tenantId);
    return this.filterProperties(uiConfig, keys, baseConfig);
  }
}

AuthConfigHandler.SUPPORTED_SIGN_IN_CONFIG_KEYS = [
  'immediateFederatedRedirect',
  'privacyPolicyUrl',
  'signInFlow',
  'signInOptions',
  'tosUrl',
];

AuthConfigHandler.DisplayMode = {
  OPTION_FIRST: 'optionFirst',
  IDENTIFIER_FIRST: 'identifierFirst',
};

AuthConfigHandler.ConfigKeys = {
  DEFAULT_CONFIG_KEY: '*',
  TOP_LEVEL_CONFIG_KEY: '_',
};

export default AuthConfigHandler;
