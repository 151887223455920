const showFormError = (errorElem: HTMLElement): void => {
  if (errorElem && errorElem.innerHTML) {
    errorElem.classList.remove('hide');
  }
};

const hideFormError = (errorElem: HTMLElement): void => {
  if (errorElem) {
    errorElem.innerHTML = '';
    errorElem.classList.add('hide');
  }
};

export { showFormError, hideFormError };
