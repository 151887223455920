import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const foxTenant = {
  signInOptions: [
    {
      hd: 'fox.com',
      provider: 'oidc.fox',
      loginHintKey: 'login_hint',
    },
  ],
};

const gannettTenant = {
  signInOptions: [
    {
      hd: 'gannett.com',
      provider: 'oidc.gannett',
      loginHintKey: 'login_hint',
    },
  ],
};

const ddmTenantProd = {
  signInOptions: [
    {
      hd: 'dotdashmdp.com',
      provider: 'oidc.ddm',
      loginHintKey: 'login_hint',
    },
  ],
};

const ddmTenantTest = {
  signInOptions: [
    {
      hd: 'itmdp.com',
      provider: 'oidc.ddm',
      loginHintKey: 'login_hint',
    },
  ],
};

const abcTenant = {
  signInOptions: [
    {
      hd: 'abc.com',
      provider: 'oidc.abc',
      loginHintKey: 'login_hint',
    },
  ],
};

const stdTenant = {
  signInOptions: [
    {
      hd: /^((?!(@trueanthem.com|@fox.com|@gannett.com|@itmdp.com|@abc.com)).)*$/,
      provider: 'oidc.hydra',
      loginHintKey: 'login_hint',
    },
  ],
};

const trueAnthemTenant = {
  signInOptions: [
    {
      hd: 'trueanthem.com',
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    },
  ],
};

const getConfigs = (apiKey, authDomain) => ({
  [apiKey]: {
    authDomain,
    displayMode: 'identifierFirst',
    tenants: {
      'fx00-d5hgi': foxTenant, // rocket
      'fx00-wqlem': foxTenant, // opus
      'gannett-8kwvb': gannettTenant, // rocket
      'gan00-63ssk': gannettTenant, // opus
      'ddm0-y9zmi': ddmTenantTest, // rocket using DDM Test Okta
      'ddm1-p0tvq': ddmTenantProd, // rocket using DDM Prod Okta
      // DO NOT ENABLE UNLESS YOU ARE TESTING IN PRD WITH DDM
      'ddm0-???t1602????': ddmTenantProd, // opus using DDM Prod Okta - CAREFUL!!!
      'abc0-oikt1': abcTenant, //rocket
      'abc0-ldnug': abcTenant, //opus
      'stnd-0aivg': stdTenant, // rocket
      'stnd-c6gbv': stdTenant, // opus
      'true-anthem-ahby8': trueAnthemTenant, // rocket
      'true-anthem-kia11': trueAnthemTenant, // opus
    },
  },
});

export default getConfigs;
