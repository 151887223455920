import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const authProviders = {
  'facebook.com': 'FacebookAuthProvider',
  'github.com': 'GithubAuthProvider',
  'google.com': 'GoogleAuthProvider',
  password: 'EmailAuthProvider',
  'twitter.com': 'TwitterAuthProvider',
  phone: 'PhoneAuthProvider',
};

const SAML_PREFIX = 'saml.';

const isSaml = function (providerId) {
  return providerId.indexOf(SAML_PREFIX) == 0;
};

const getAuthProvider = (providerId) => {
  if (authProviders[providerId] && firebase.auth[authProviders[providerId]]) {
    return new firebase.auth[authProviders[providerId]]();
  } else if (isSaml(providerId)) {
    return new firebase.auth['SAMLAuthProvider'](providerId);
  } else {
    return new firebase.auth['OAuthProvider'](providerId);
  }
};

export default getAuthProvider;
