class AuthConfig {
  constructor() {
    this.instance = {};
  }

  _has(name) {
    return name.toLowerCase() in this.instance;
  }

  _get(name) {
    return this.instance[name.toLowerCase()];
  }

  _set(name, value) {
    this.instance[name.toLowerCase()] = value;
  }

  define(name, value) {
    if (this._has(name)) {
      throw new Error('Configuration ' + name + ' has already been defined.');
    }
    this._set(name, value);
  }

  update(name, value) {
    if (!this._has(name)) {
      throw new Error('Configuration ' + name + ' is not defined.');
    }
    this._set(name, value);
  }

  get(name) {
    if (!this._has(name)) {
      throw new Error('Configuration ' + name + ' is not defined.');
    }
    return this._get(name);
  }

  getRequired(name) {
    var value = this.get(name);
    if (!value) {
      throw new Error('Configuration ' + name + ' is required.');
    }
    return value;
  }
}

export default AuthConfig;
