import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import {
  FirebaseAuth,
  IdTokenResult,
  User,
  UserCredential,
} from '@firebase/auth-types';

import * as ciap from 'gcip-iap';

import AuthConfigHandler from './authConfigHandler.js';
import getAuthProvider from './authProviders.js';

import { getElementById, getElementValueById } from '../lib/dom';

import { showEmailError, validateEmail } from '../validation/email';
import { Modal } from '../modal';

class AuthenticationHandler implements ciap.AuthenticationHandler {
  private readonly configs: any;
  private send: boolean;

  public onEmailEntered: (email?: string) => void;

  constructor(configs: any) {
    this.configs = {};
    Object.keys(configs).forEach((apiKey) => {
      this.configs[apiKey] = new AuthConfigHandler(configs[apiKey]);
    });

    this.send = true;

    this.onEmailEntered = () => {
      console.error(
        'Email address can be entered only during tenant selection.',
      );
    };
  }

  private static unrecoverableError(error: Error | ciap.CIAPError) {
    console.error(error);
    window.location.replace(`/identity/error?message=${error.message}`);
  }

  private postData(
    mode: string,
    data: {
      email?: string;
      tenantId?: string;
      providerId?: string;
      _csrf: string;
    },
  ): Promise<Response> {
    const url = `/identity/tenant/${mode}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      cache: 'no-store',
    });
  }

  /* extractUserId looks for a claim called preferred_username to get the login ID of the user.
   * This field is set by Okta systems and represent the actual user ID of the user.
   * See https://developer.okta.com/docs/reference/api/oidc/#id-token-payload for details.
   * If this field is not present, then use the email claim. If that is not present,
   * then simply use the user supplied email address.
   * Note that the hydra provider and google provider do not set this field, so
   * it uses the correct login ID for these providers using the other fields as
   * indicated.
   */
  private extractUserId(token: IdTokenResult): string | null {
    if (token?.claims?.firebase?.sign_in_attributes?.preferred_username) {
      const res = token.claims.firebase.sign_in_attributes.preferred_username;
      const { valid } = validateEmail(res);
      if (valid) {
        return res;
      }
    }
    return token.claims.email;
  }

  private handleAuthStateChanged(auth: firebase.auth.Auth | null) {
    const mode = new URLSearchParams(window.location.search).get('mode') || '';
    if (!auth) {
      console.debug('auth is null');
      return;
    }

    if (!this.send) {
      console.debug('already sent');
      return;
    }

    if (mode === 'login') {
      auth
        .getRedirectResult()
        .then(async (userCredential) => {
          const ucUser = userCredential?.user;
          let email: string | null = null;
          if (ucUser) {
            email = ucUser.email;
            await ucUser.getIdTokenResult().then((token: IdTokenResult) => {
              const userId = this.extractUserId(token);
              email = userId || email;
            });
          }
          if (email) {
            this.send = false;

            const tenantId = ucUser?.tenantId || undefined;
            const providerId = ucUser?.providerData[0]?.providerId || undefined;
            return this.postData(mode, {
              email,
              tenantId,
              providerId,
              _csrf: getElementValueById('_csrf'),
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    } else if (mode === 'signout') {
      this.send = false;

      const email = undefined;
      const tenantId = undefined;
      const providerId = undefined;
      this.postData(mode, {
        email,
        tenantId,
        providerId,
        _csrf: getElementValueById('_csrf'),
      }).catch((error) => {
        this.handleError(error);
      });
    }
  }

  public selectTenant(
    projectConfig: ciap.ProjectConfig,
    tenantIds: string[],
  ): Promise<ciap.SelectedTenantInfo> {
    const apiKey = projectConfig['apiKey'];

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!this.configs.hasOwnProperty(apiKey)) {
        const error = new Error(`Invalid API key in selectTenant(): ${apiKey}`);
        this.handleError(error);
        reject(error);
        return;
      }

      this.onEmailEntered = (email) => {
        // Find tenant for the entered email address
        for (let i = 0; i < tenantIds.length; i++) {
          const providerIds = this.configs[apiKey].getProvidersForTenant(
            tenantIds[i] || AuthConfigHandler.ConfigKeys.TOP_LEVEL_CONFIG_KEY,
            email,
          );
          // Resolve with the first matching tenant with available providers
          if (providerIds.length !== 0) {
            const selectedTenantInfo = {
              tenantId: tenantIds[i],
              providerIds,
              email,
            };
            resolve(selectedTenantInfo);
            return;
          }
        }
        const error = new Error(`No matching tenant for email ${email}`);
        this.handleError(error);
        reject(error);
      };
    });
  }

  public getAuth(apiKey: string, tenantId: string): FirebaseAuth {
    // eslint-disable-next-line no-prototype-builtins
    if (!this.configs.hasOwnProperty(apiKey)) {
      throw new Error(`Invalid API key in getAuth(): ${apiKey}`);
    }

    // Validate that configuration is available for selected tenant.
    this.configs[apiKey].validateTenantId(tenantId);

    let auth: firebase.auth.Auth | null = null;
    try {
      auth = firebase.app(tenantId).auth();
    } catch (error) {
      const options = {
        apiKey: apiKey,
        authDomain: this.configs[apiKey].getAuthDomain(),
      };
      const app = firebase.initializeApp(options, tenantId);
      auth = app.auth();
      auth.tenantId = tenantId;
    }

    auth?.onAuthStateChanged(() => {
      this.handleAuthStateChanged(auth);
    });

    return auth as any;
  }

  public startSignIn(
    auth: FirebaseAuth,
    tenantInfo: ciap.SelectedTenantInfo,
  ): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      const apiKey = auth.app.options.apiKey;
      if (!apiKey) {
        reject(new Error('Could not get API key'));
        return;
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!this.configs.hasOwnProperty(apiKey)) {
        reject(new Error(`Invalid API key in startSignIn(): ${apiKey}`));
        return;
      }

      const signInConfig = this.configs[apiKey].getSignInConfigForTenant(
        auth.tenantId,
        tenantInfo && tenantInfo.providerIds,
      );

      const providerId = signInConfig.signInOptions[0].provider;

      const provider = getAuthProvider(providerId);
      if (!provider) {
        reject(new Error(`Invalid auth provider ID: ${providerId}`));
      }

      if (provider.setCustomParameters && tenantInfo) {
        let loginHintKey;
        // Set Google loginHintKey automatically.
        // All other loginHintKeys must be specified in signInOptions config.
        if (providerId == firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
          loginHintKey = 'login_hint';
        } else {
          loginHintKey = signInConfig.signInOptions[0].loginHintKey;
        }

        if (loginHintKey) {
          const customParameters = {
            [loginHintKey]: tenantInfo.email,
          };
          provider.setCustomParameters(customParameters);
        }
      }

      auth.signInWithRedirect(provider).catch((error) => {
        reject(error);
      });
    });
  }

  public processUser(user: User): Promise<User> {
    const isLoginMode = getElementValueById('isLoginMode');
    if (isLoginMode.toLowerCase() !== 'true') {
      return Promise.resolve(user);
    }
    const csrfToken = getElementValueById('_csrf');
    let userEmail = user.email;

    const getHasUserAcknowledgedPolicy = async (): Promise<boolean> => {
      try {
        await user.getIdTokenResult().then((token: IdTokenResult) => {
          const userId = this.extractUserId(token);
          userEmail = userId || userEmail;
        });
        if (!userEmail) {
          return Promise.reject({
            message:
              'Unable to verify user privacy policy: Could not get user email.',
            code: 'privacy-policy',
          });
        }

        const response = await fetch('/identity/privacy-policy', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ _csrf: csrfToken, userEmail }),
        });
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          }),
        );
      } catch (err) {
        console.log(`Unable to retrieve user privacy policy: ${err}`);
        return Promise.reject({
          message: 'Unable to verify user privacy policy: User Not Found.',
          code: 'privacy-policy',
        });
      }
    };

    const handleConfirmPrivacyPolicy = (resolve: any) => () => {
      return fetch('/identity/privacy-policy', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _csrf: csrfToken, userEmail }),
      }).then(() => {
        resolve(user);
      });
    };

    const handleRejectPrivacyPolicy = () => {
      window.location.replace(
        '/identity/logout?gcp-iap-mode=CLEAR_LOGIN_COOKIE',
      );
    };

    return getHasUserAcknowledgedPolicy().then(
      (isPolicyAcknowledged: boolean) => {
        if (isPolicyAcknowledged) {
          return Promise.resolve(user);
        }

        return new Promise((resolve, reject) => {
          const modal = new Modal({
            id: 'privacyModal',
            onReject: handleRejectPrivacyPolicy,
            onConfirm: handleConfirmPrivacyPolicy(resolve),
          });
          modal.open();
        });
      },
    );
  }

  public showProgressBar(): void {
    return;
  }

  public hideProgressBar(): void {
    return;
  }

  public completeSignOut(): Promise<void> {
    return Promise.resolve();
  }

  public handleError(error: Error | ciap.CIAPError): void {
    if ('code' in error) {
      // https://cloud.google.com/iap/docs/create-custom-auth-ui#handling_errors
      if (error.code === 'restart-process') {
        window.location.replace(
          "/identity/logout?gcp-iap-mode=CLEAR_LOGIN_COOKIE&error=Let's try that again. Your browser session expired and for security reasons we need you to log in again.",
        );
      } else {
        AuthenticationHandler.unrecoverableError(error);
      }
    } else {
      const emailElem = <HTMLInputElement>getElementById('email');
      const emailErrorElem = <HTMLElement>getElementById('emailError');
      showEmailError(emailElem, emailErrorElem, error.message);
      console.error(error.message);
    }
  }
}

export default AuthenticationHandler;
