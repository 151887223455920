const getElementById = (id: string): HTMLElement | null => {
  const elem = document.getElementById(id);
  if (!elem) {
    console.debug(`Could not get element with id "${id}"`);
  }
  return elem;
};

const getElementValueById = (id: string): string => {
  const elem = <HTMLInputElement>getElementById(id);
  return (elem && elem.value) || '';
};

const onDocumentReady = (fn: () => void): void => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', fn);
  } else {
    fn();
  }
};
export { getElementById, getElementValueById, onDocumentReady };
