import { validateEmail } from '../../../validation/email';

const showEmailError = (
  inputElem: HTMLInputElement,
  errorElem: HTMLElement,
  message: string,
): void => {
  if (inputElem) {
    inputElem.classList.add('input-invalid');
  }

  if (errorElem) {
    errorElem.innerHTML = message;
    errorElem.classList.remove('hide');
  }
};

const hideEmailError = (
  inputElem: HTMLInputElement,
  errorElem: HTMLElement,
): void => {
  if (inputElem) {
    inputElem.classList.remove('input-invalid');
  }

  if (errorElem) {
    errorElem.innerHTML = '';
    errorElem.classList.add('hide');
  }
};

export { validateEmail, showEmailError, hideEmailError };
