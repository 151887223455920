import { getElementById } from './lib/dom';

type ModalConfiguration = {
  id: string;
  onReject: () => void;
  onConfirm: () => void;
};
export class Modal {
  element: HTMLElement | null;
  reject: () => void;
  confirm: () => void;
  constructor(config: ModalConfiguration) {
    this.element = getElementById(config.id);
    this.reject = config.onReject;
    this.confirm = config.onConfirm;
    this._eventHandlers();
  }

  open() {
    if (this.element) {
      this.element.style.display = 'flex';
    }
  }

  close() {
    if (this.element) {
      this.element.style.display = 'none';
    }
  }

  _eventHandlers() {
    if (!this.element) {
      return;
    }
    this.element.querySelectorAll('.reject').forEach((element) => {
      (element as HTMLElement).onclick = (event) => {
        event.preventDefault();
        this.close();
        this.reject();
      };
    });

    this.element.querySelectorAll('.confirm').forEach((element) => {
      (element as HTMLElement).onclick = (event) => {
        event.preventDefault();
        this.close();
        this.confirm();
      };
    });
  }
}
