import * as Validation from '../types/validation';

const validateEmail = (email: string): Validation.Result => {
  const result = { valid: true, message: '' };

  // See https://owasp.org/www-community/OWASP_Validation_Regex_Repository
  const emailRegex =
    /^[a-zA-Z0-9'_+&*-]+(?:\.[a-zA-Z0-9'_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;

  if (!email || !emailRegex.test(email)) {
    result.valid = false;
    result.message = 'A valid email address is required';
  } else if (email.length > 254) {
    result.valid = false;
    result.message = 'Email address must be 254 characters or less';
  }

  return result;
};

export { validateEmail };
